<template>
  <gw-dialog v-model="dialog" :loading="loading" @open:dialog="onOpen">
    <template #activator="{ on }">
      <invoice-number-btn
        v-if="btnActivator"
        :invoice="item"
        activator
        v-on="on"
      />
      <invoice-card v-else :item="item" v-on="on" />
    </template>

    <invoice-full-preview v-if="dialog && !loading" :item="obItemData" />

    <template #prepend-actions>
      <v-btn
        v-if="!hideVisitBtn"
        color="primary"
        text
        @click="onVisit"
        v-text="$t('visit')"
      />
    </template>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import GwDialog from "@/components/common/GwDialog.vue";
import type { InvoiceData } from "@planetadeleste/vue-mc-gw";
import { Invoice } from "@planetadeleste/vue-mc-gw";
import InvoiceFullPreview from "@/modules/invoices/components/preview/InvoiceFullPreview.vue";
import InvoiceCard from "@/modules/invoices/components/InvoiceCard.vue";
import { toString } from "lodash";
import InvoiceNumberBtn from "@/modules/invoices/components/InvoiceNumberBtn.vue";

@Component({
  components: { InvoiceNumberBtn, InvoiceCard, InvoiceFullPreview, GwDialog },
})
export default class InvoicePreviewDialog extends Vue {
  @Prop(Object) readonly item!: InvoiceData;
  @Prop(Boolean) readonly btnActivator!: boolean;

  dialog = false;
  loading = false;
  obModel: Invoice = new Invoice();

  get obItemData(): InvoiceData {
    return this.obModel.attributes as InvoiceData;
  }

  get hideVisitBtn() {
    return (
      this.$route.name === "invoices.update" &&
      this.$route.params.id === toString(this.obItemData.id)
    );
  }

  async onOpen() {
    if (this.obModel.id || !this.item.id) {
      return;
    }

    this.loading = true;
    this.obModel.set("id", this.item.id);
    await this.obModel.fetch();
    this.loading = false;
  }

  async onVisit() {
    this.dialog = false;
    await this.$router.push({
      name: "invoices.update",
      params: { id: toString(this.obItemData.id) },
    });
  }
}
</script>
